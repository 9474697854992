import { ICON_SPRITE_URL } from './constants';

document.documentElement.className += ' has-optimized-font has-fonts';

/**
 * Inject icons
 */
const request = new XMLHttpRequest();
request.open('GET', ICON_SPRITE_URL, true);
request.onreadystatechange = () => {
  if (request.readyState === 4) {
    const div = document.createElement('div');
    const ready = () => document.body.appendChild(div);

    div.setAttribute('style', 'height: 0; width: 0; position: absolute; visibility: hidden; display: none');
    div.innerHTML = request.responseText;

    if (document.readyState === 'complete') {
      ready();
    } else {
      document.addEventListener('DOMContentLoaded', () => ready(), false);
      window.addEventListener('load', () => ready(), false);
    }
  }
};
request.send();
